<template>
  <div>
    <h1>Das Movie-Search-Tool</h1>
    <form class="form-inline my-5" @submit.prevent="submitSearch">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Name des Films eingeben ..."
          aria-label="Suchanfrage"
          aria-describedby="button-addon2"
          v-model="searchRequest"
          required
        />
        <button
          class="btn btn-outline-primary"
          id="button-addon2"
          type="submit"
        >
          Suche starten
        </button>
      </div>
    </form>

    <div v-if="movieData.total_results">
      <div v-for="movie in movieData.results" :key="movie.id" class="card mb-3">
        <div class="row g-0 align-items-center">
          <div class="col-md-5 text-start">
            <img
              class="img-fluid rounded-start"
              v-bind:src="'https://image.tmdb.org/t/p/w400' + movie.poster_path"
            />
          </div>
          <div class="col-md-7">
            <div class="card-body">              
              <h2 class="card-title">
                {{ movie.original_title }}
              </h2>
              <p class="card-text">
                <small class="text-muted pe-2" title="Erscheinungsdatum"
                  >📆 {{ movie.release_date }}</small
                >
                <small class="text-muted pe-2" title="Bewertungsdurchschnitt"
                  >⭐ {{ movie.vote_average }}</small
                >
                <small class="text-muted pe-2" title="Bewertungsanzahl"
                  >🗣 {{ movie.vote_count }}</small
                >
              </p>
              <p class="card-text text-muted">
                {{ movie.overview }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 v-else>
      Leider wurde nichts gefunden
    </h2>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      movieData: [],
      searchRequest: "",
    };
  },
  methods: {
    submitSearch() {
      fetch(
        "https://api.themoviedb.org/3/search/movie?api_key=2ff2b842380979ada1e6bc585b8d84bd&language=de-DE&query=" +
          this.searchRequest
      )
        .then((response) => response.json())
        .then((data) => (this.movieData = data))
        .catch((error) => {
          console.error("Request error:", error);
        });
    },
  },
  created() {
    (document.title = "Movie-Search-Tool"),
      fetch(
        "https://api.themoviedb.org/3/search/movie?api_key=2ff2b842380979ada1e6bc585b8d84bd&language=de-DE&query=hot%20fuzz"
      )
        .then((response) => response.json())
        .then((data) => (this.movieData = data));
  },
};
</script>

<style></style>
